import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='nf-icon-container' {{did-insert this.fetchNotifications}}>\n  <button\n    type='button'\n    local-class='nf-icon nf-button'\n    {{on 'click' this.onClick}}\n    title='{{this.notificationService.unReadCount}} unread notifications'\n    data-test-bell-icon\n  >\n    <div local-class='icon-container' data-test-bell-icon-trigger>\n      <AkIcon @iconName='notifications' data-test-notification-icon />\n\n      {{#if this.notificationService.hasUnReadNotifications}}\n        <div local-class='nf-unread-dot' data-test-unread-dot></div>\n      {{/if}}\n    </div>\n  </button>\n\n  <NotificationsDropdown\n    @product={{@product}}\n    @anchorRef={{this.anchorRef}}\n    @onClose={{this.closeNotification}}\n  />\n</div>", {"contents":"<div local-class='nf-icon-container' {{did-insert this.fetchNotifications}}>\n  <button\n    type='button'\n    local-class='nf-icon nf-button'\n    {{on 'click' this.onClick}}\n    title='{{this.notificationService.unReadCount}} unread notifications'\n    data-test-bell-icon\n  >\n    <div local-class='icon-container' data-test-bell-icon-trigger>\n      <AkIcon @iconName='notifications' data-test-notification-icon />\n\n      {{#if this.notificationService.hasUnReadNotifications}}\n        <div local-class='nf-unread-dot' data-test-unread-dot></div>\n      {{/if}}\n    </div>\n  </button>\n\n  <NotificationsDropdown\n    @product={{@product}}\n    @anchorRef={{this.anchorRef}}\n    @onClose={{this.closeNotification}}\n  />\n</div>","moduleName":"irene/components/notifications-page/bell-icon/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/bell-icon/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';

import type AkNotificationsService from 'irene/services/ak-notifications';
import type SkNotificationsService from 'irene/services/sk-notifications';
import type { NotificationServiceMap } from '..';

interface NotificationsPageBellIconSignature {
  Args: {
    product: IreneProductVariants;
  };
}

export default class NotificationsPageBellIconComponent extends Component<NotificationsPageBellIconSignature> {
  @service declare akNotifications: AkNotificationsService;
  @service declare skNotifications: SkNotificationsService;

  @tracked anchorRef: HTMLElement | null = null;

  notificationServiceMap: NotificationServiceMap;

  constructor(
    owner: unknown,
    args: NotificationsPageBellIconSignature['Args']
  ) {
    super(owner, args);

    this.notificationServiceMap = {
      appknox: this.akNotifications,
      storeknox: this.skNotifications,
    };
  }

  get notificationService() {
    return this.notificationServiceMap[this.args.product];
  }

  @action
  onClick(event: Event) {
    if (this.anchorRef) {
      this.closeNotification();

      return;
    }

    this.anchorRef = event.currentTarget as HTMLElement;
    this.fetchNotifications();
  }

  @action
  closeNotification() {
    this.anchorRef = null;
  }

  @action
  fetchNotifications() {
    this.notificationService.fetchUnRead.perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsPage::BellIcon': typeof NotificationsPageBellIconComponent;
  }
}
