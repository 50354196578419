import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<NotificationsPage::NamespaceMessage\n  @context={{@context}}\n  @primaryMessage={{t\n    'notificationModule.messages.nf-str-url-nsreqstd2'\n    htmlSafe=true\n    current_requester_username=@context.current_requester_username\n    initial_requester_username=@context.initial_requester_username\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    namespace_created_on=(format-date\n      @context.namespace_created_on day='numeric' month='long' year='numeric'\n    )\n  }}\n/>", {"contents":"<NotificationsPage::NamespaceMessage\n  @context={{@context}}\n  @primaryMessage={{t\n    'notificationModule.messages.nf-str-url-nsreqstd2'\n    htmlSafe=true\n    current_requester_username=@context.current_requester_username\n    initial_requester_username=@context.initial_requester_username\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    namespace_created_on=(format-date\n      @context.namespace_created_on day='numeric' month='long' year='numeric'\n    )\n  }}\n/>","moduleName":"irene/components/notifications-page/messages/nf-str-url-nsreqstd2/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-str-url-nsreqstd2/index.hbs"}});
import Component from '@glimmer/component';
import { type NfStrUrlNsreqstd2Context } from './context';

export interface NotificationsPageMessagesNfStrUrlNsreqstd2ComponentArgs {
  Args: {
    context: NfStrUrlNsreqstd2Context;
  };
}

export default class NotificationsPageMessagesNfStrUrlNsreqstd2Component extends Component<NotificationsPageMessagesNfStrUrlNsreqstd2ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-str-url-nsreqstd2': typeof NotificationsPageMessagesNfStrUrlNsreqstd2Component;
  }
}
