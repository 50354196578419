import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <div data-test-nf-newversn-primary-message>\n    {{t\n      'notificationModule.messages.nf-sk-newversn'\n      platform_display=@context.platform_display\n      app_name=@context.app_name\n      package_name=@context.package_name\n      version_unscanned=@context.version_unscanned\n      htmlSafe=true\n    }}\n  </div>\n\n  <div>\n    <div class='pt-1'>\n      <AkLink\n        @title='{{t \"notificationModule.viewMonitoringResults\"}}'\n        @color='primary'\n        @route='authenticated.storeknox.inventory-details.unscanned-version'\n        @underline='always'\n        @model={{@context.sk_app_id}}\n      >\n        <:default>\n          {{t 'notificationModule.viewMonitoringResults'}}\n        </:default>\n      </AkLink>\n    </div>\n  </div>\n</div>", {"contents":"<div>\n  <div data-test-nf-newversn-primary-message>\n    {{t\n      'notificationModule.messages.nf-sk-newversn'\n      platform_display=@context.platform_display\n      app_name=@context.app_name\n      package_name=@context.package_name\n      version_unscanned=@context.version_unscanned\n      htmlSafe=true\n    }}\n  </div>\n\n  <div>\n    <div class='pt-1'>\n      <AkLink\n        @title='{{t \"notificationModule.viewMonitoringResults\"}}'\n        @color='primary'\n        @route='authenticated.storeknox.inventory-details.unscanned-version'\n        @underline='always'\n        @model={{@context.sk_app_id}}\n      >\n        <:default>\n          {{t 'notificationModule.viewMonitoringResults'}}\n        </:default>\n      </AkLink>\n    </div>\n  </div>\n</div>","moduleName":"irene/components/notifications-page/messages/nf-sk-newversn/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-sk-newversn/index.hbs"}});
import Component from '@glimmer/component';
import { type NfSkNewversnContext } from './context';

export interface NotificationsPageMessagesNfSkNewversnComponentArgs {
  Args: {
    context: NfSkNewversnContext;
  };
}

export default class NotificationsPageMessagesNfSkNewversnComponent extends Component<NotificationsPageMessagesNfSkNewversnComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-sk-newversn': typeof NotificationsPageMessagesNfSkNewversnComponent;
  }
}
