import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <div data-test-nf-nsrejctd2-primary-message>\n    {{t\n      'notificationModule.messages.nf-nsrejctd2'\n      htmlSafe=true\n      platform_display=@context.platform_display\n      namespace_value=@context.namespace_value\n      requester_username=@context.requester_username\n      moderator_username=@context.moderator_username\n    }}\n  </div>\n  <div>\n    <div class='pt-1' data-test-nf-nsrejctd2-link>\n      <AkLink\n        @color='primary'\n        @route='authenticated.dashboard.organization.namespaces'\n        @underline='always'\n        @title='{{t \"notificationModule.viewNamespaces\"}}'\n      >\n        {{t 'notificationModule.viewNamespaces'}}\n      </AkLink>\n    </div>\n  </div>\n</div>", {"contents":"<div>\n  <div data-test-nf-nsrejctd2-primary-message>\n    {{t\n      'notificationModule.messages.nf-nsrejctd2'\n      htmlSafe=true\n      platform_display=@context.platform_display\n      namespace_value=@context.namespace_value\n      requester_username=@context.requester_username\n      moderator_username=@context.moderator_username\n    }}\n  </div>\n  <div>\n    <div class='pt-1' data-test-nf-nsrejctd2-link>\n      <AkLink\n        @color='primary'\n        @route='authenticated.dashboard.organization.namespaces'\n        @underline='always'\n        @title='{{t \"notificationModule.viewNamespaces\"}}'\n      >\n        {{t 'notificationModule.viewNamespaces'}}\n      </AkLink>\n    </div>\n  </div>\n</div>","moduleName":"irene/components/notifications-page/messages/nf-nsrejctd2/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-nsrejctd2/index.hbs"}});
import Component from '@glimmer/component';
import { type NfNsrejctd2Context } from './context';

export interface NotificationsPageMessagesNfNsrejctd2ComponentArgs {
  Args: {
    context: NfNsrejctd2Context;
  };
}

export default class NotificationsPageMessagesNfNsrejctd2Component extends Component<NotificationsPageMessagesNfNsrejctd2ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-nsrejctd2': typeof NotificationsPageMessagesNfNsrejctd2Component;
  }
}
