import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTooltip @placement='top' @arrow={{true}} @title={{@skAppVersion.version}}>\n  <AkTypography\n    @noWrap={{true}}\n    {{style maxWidth='100px'}}\n    data-test-storeknoxInventoryDetails-unscannedVersionTable-storeVersion\n  >\n    {{@skAppVersion.version}}\n  </AkTypography>\n</AkTooltip>", {"contents":"<AkTooltip @placement='top' @arrow={{true}} @title={{@skAppVersion.version}}>\n  <AkTypography\n    @noWrap={{true}}\n    {{style maxWidth='100px'}}\n    data-test-storeknoxInventoryDetails-unscannedVersionTable-storeVersion\n  >\n    {{@skAppVersion.version}}\n  </AkTypography>\n</AkTooltip>","moduleName":"irene/components/storeknox/inventory-details/unscanned-version/table/store-version/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/unscanned-version/table/store-version/index.hbs"}});
import Component from '@glimmer/component';
import type SkAppVersionModel from 'irene/models/sk-app-version';

interface StoreknoxInventoryDetailsUnscannedVersionTableStoreVersionSignature {
  Args: {
    skAppVersion: SkAppVersionModel;
  };
}

export default class StoreknoxInventoryDetailsUnscannedVersionTableStoreVersionComponent extends Component<StoreknoxInventoryDetailsUnscannedVersionTableStoreVersionSignature> {}
