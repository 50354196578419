import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-nf-nsautoapprvd2-primary-message>\n  {{t\n    'notificationModule.messages.nf-nsautoapprvd2'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    requester_username=@context.requester_username\n  }}\n</div>", {"contents":"<div data-test-nf-nsautoapprvd2-primary-message>\n  {{t\n    'notificationModule.messages.nf-nsautoapprvd2'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    requester_username=@context.requester_username\n  }}\n</div>","moduleName":"irene/components/notifications-page/messages/nf-nsautoapprvd2/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-nsautoapprvd2/index.hbs"}});
import Component from '@glimmer/component';
import { type NfNsautoapprvd2Context } from './context';

export interface NotificationsPageMessagesNfNsautoapprvd2ComponentArgs {
  Args: {
    context: NfNsautoapprvd2Context;
  };
}

export default class NotificationsPageMessagesNfNsautoapprvd2Component extends Component<NotificationsPageMessagesNfNsautoapprvd2ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-nsautoapprvd2': typeof NotificationsPageMessagesNfNsautoapprvd2Component;
  }
}
