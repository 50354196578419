import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTable data-test-component-list-skeleton-loader as |t|>\n  <t.head @columns={{this.columns}} />\n  <t.body @rows={{this.loadingMockData}} as |b|>\n    <b.row as |r|>\n      <r.cell>\n        <AkSkeleton @width='100px' @height='20px' />\n      </r.cell>\n    </b.row>\n  </t.body>\n</AkTable>", {"contents":"<AkTable data-test-component-list-skeleton-loader as |t|>\n  <t.head @columns={{this.columns}} />\n  <t.body @rows={{this.loadingMockData}} as |b|>\n    <b.row as |r|>\n      <r.cell>\n        <AkSkeleton @width='100px' @height='20px' />\n      </r.cell>\n    </b.row>\n  </t.body>\n</AkTable>","moduleName":"irene/components/sbom/scan-details/skeleton-loader-list/index.hbs","parseOptions":{"srcName":"irene/components/sbom/scan-details/skeleton-loader-list/index.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';

import type IntlService from 'ember-intl/services/intl';

export default class SbomScanDetailsSkeletonLoaderListComponent extends Component {
  @service declare intl: IntlService;

  get columns() {
    return [
      {
        name: this.intl.t('sbomModule.componentName'),
        width: 150,
      },
      {
        name: this.intl.t('sbomModule.componentType'),
      },
      {
        name: this.intl.t('dependencyType'),
      },
      {
        name: this.intl.t('status'),
      },
    ];
  }

  get loadingMockData() {
    return new Array(8).fill({});
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ScanDetails::SkeletonLoaderList': typeof SbomScanDetailsSkeletonLoaderListComponent;
  }
}
