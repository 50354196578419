import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-nf-nfUpldfailnprjdeny1-primary-message>\n  {{t\n    'notificationModule.messages.nf-upldfailnprjdeny1'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    package_name=@context.package_name\n  }}\n</div>", {"contents":"<div data-test-nf-nfUpldfailnprjdeny1-primary-message>\n  {{t\n    'notificationModule.messages.nf-upldfailnprjdeny1'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    package_name=@context.package_name\n  }}\n</div>","moduleName":"irene/components/notifications-page/messages/nf-upldfailnprjdeny1/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-upldfailnprjdeny1/index.hbs"}});
import Component from '@glimmer/component';
import { type NfUpldfailnprjdeny1Context } from './context';

export interface NotificationsPageMessagesNfUpldfailnprjdeny1ComponentArgs {
  Args: {
    context: NfUpldfailnprjdeny1Context;
  };
}

export default class NotificationsPageMessagesNfUpldfailnprjdeny1Component extends Component<NotificationsPageMessagesNfUpldfailnprjdeny1ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-upldfailnprjdeny1': typeof NotificationsPageMessagesNfUpldfailnprjdeny1Component;
  }
}
