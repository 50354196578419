import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @alignItems='flex-start'\n  @direction='column'\n  @width='full'\n  {{style alignSelf='flex-start'}}\n  data-test-component-tree-skeleton-loader\n>\n  <AkStack\n    @width='full'\n    @alignItems='center'\n    @spacing='1'\n    local-class='tree-package-name-container'\n  >\n    <AkSkeleton @width='500px' @height='16px' class='my-1' />\n  </AkStack>\n\n  <AkTree\n    @treeData={{this.skeletonTreeData}}\n    @expanded={{this.expanded}}\n    @onExpand={{this.noop}}\n    @checked={{this.expanded}}\n    @onCheck={{this.noop}}\n  >\n    <AkStack local-class='node-container'>\n      <div local-class='tree-connectors' />\n      <AkSkeleton @width='500px' @height='22px' class='my-1' />\n    </AkStack>\n  </AkTree>\n</AkStack>", {"contents":"<AkStack\n  @alignItems='flex-start'\n  @direction='column'\n  @width='full'\n  {{style alignSelf='flex-start'}}\n  data-test-component-tree-skeleton-loader\n>\n  <AkStack\n    @width='full'\n    @alignItems='center'\n    @spacing='1'\n    local-class='tree-package-name-container'\n  >\n    <AkSkeleton @width='500px' @height='16px' class='my-1' />\n  </AkStack>\n\n  <AkTree\n    @treeData={{this.skeletonTreeData}}\n    @expanded={{this.expanded}}\n    @onExpand={{this.noop}}\n    @checked={{this.expanded}}\n    @onCheck={{this.noop}}\n  >\n    <AkStack local-class='node-container'>\n      <div local-class='tree-connectors' />\n      <AkSkeleton @width='500px' @height='22px' class='my-1' />\n    </AkStack>\n  </AkTree>\n</AkStack>","moduleName":"irene/components/sbom/scan-details/skeleton-loader-tree/index.hbs","parseOptions":{"srcName":"irene/components/sbom/scan-details/skeleton-loader-tree/index.hbs"}});
import Component from '@glimmer/component';

export interface SbomScanDetailsSkeletonLoaderTreeSignature {
  Args: {
    isFilteredTree: boolean;
  };
}

export default class SbomScanDetailsSkeletonLoaderTreeComponent extends Component<SbomScanDetailsSkeletonLoaderTreeSignature> {
  noop() {}

  expanded = ['placeholder-1'];

  skeletonTreeData = Array.from(
    { length: this.args.isFilteredTree ? 3 : 12 },
    (_, index) => ({
      key: `placeholder-${index + 1}`,
      title: '',
      children: [],
    })
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ScanDetails::SkeletonLoaderTree': typeof SbomScanDetailsSkeletonLoaderTreeComponent;
  }
}
