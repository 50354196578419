import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <div data-test-nf-newversn-primary-message>\n    {{t\n      'notificationModule.messages.nf-am-newversn'\n      platform_display=@context.platform_display\n      app_name=@context.app_name\n      package_name=@context.package_name\n      version_unscanned=@context.version_unscanned\n      htmlSafe=true\n    }}\n  </div>\n\n  <div>\n    <div class='pt-1'>\n      <AkLink\n        @color='primary'\n        @route='authenticated.dashboard.app-monitoring.monitoring-details.index'\n        @underline='always'\n        @title='{{t \"notificationModule.viewStoreMonitoring\"}}'\n        @model={{@context.am_app_id}}\n      >\n        <:default>\n          {{t 'notificationModule.viewStoreMonitoringResults'}}\n        </:default>\n      </AkLink>\n    </div>\n  </div>\n</div>", {"contents":"<div>\n  <div data-test-nf-newversn-primary-message>\n    {{t\n      'notificationModule.messages.nf-am-newversn'\n      platform_display=@context.platform_display\n      app_name=@context.app_name\n      package_name=@context.package_name\n      version_unscanned=@context.version_unscanned\n      htmlSafe=true\n    }}\n  </div>\n\n  <div>\n    <div class='pt-1'>\n      <AkLink\n        @color='primary'\n        @route='authenticated.dashboard.app-monitoring.monitoring-details.index'\n        @underline='always'\n        @title='{{t \"notificationModule.viewStoreMonitoring\"}}'\n        @model={{@context.am_app_id}}\n      >\n        <:default>\n          {{t 'notificationModule.viewStoreMonitoringResults'}}\n        </:default>\n      </AkLink>\n    </div>\n  </div>\n</div>","moduleName":"irene/components/notifications-page/messages/nf-am-newversn/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-am-newversn/index.hbs"}});
import Component from '@glimmer/component';
import { type NfAmNewversnContext } from './context';

export interface NotificationsPageMessagesNfAmNewversnComponentArgs {
  Args: {
    context: NfAmNewversnContext;
  };
}

export default class NotificationsPageMessagesNfAmNewversnComponent extends Component<NotificationsPageMessagesNfAmNewversnComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-am-newversn': typeof NotificationsPageMessagesNfAmNewversnComponent;
  }
}
