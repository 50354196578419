import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span data-test-nf-upldfailpayrq1-primary-message>\n  {{t\n    'notificationModule.messages.nf-upldfailpayrq1'\n    htmlSafe=true\n    package_name=@context.package_name\n  }}\n</span>", {"contents":"<span data-test-nf-upldfailpayrq1-primary-message>\n  {{t\n    'notificationModule.messages.nf-upldfailpayrq1'\n    htmlSafe=true\n    package_name=@context.package_name\n  }}\n</span>","moduleName":"irene/components/notifications-page/messages/nf-upldfailpayrq1/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-upldfailpayrq1/index.hbs"}});
import Component from '@glimmer/component';
import { type NfUpldfailpayrq1Context } from './context';

export interface NotificationsPageMessagesNfUpldfailpayrq1ComponentArgs {
  Args: {
    context: NfUpldfailpayrq1Context;
  };
}

export default class NotificationsPageMessagesNfUpldfailpayrq1Component extends Component<NotificationsPageMessagesNfUpldfailpayrq1ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-upldfailpayrq1': typeof NotificationsPageMessagesNfUpldfailpayrq1Component;
  }
}
