import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <div data-test-nf-nsapprvd2-primary-message>\n    {{t\n      'notificationModule.messages.nf-nsapprvd2'\n      htmlSafe=true\n      platform_display=@context.platform_display\n      namespace_value=@context.namespace_value\n      namespace_created_on=@context.namespace_created_on\n      requester_username=@context.requester_username\n      moderator_username=@context.moderator_username\n    }}\n  </div>\n  <div>\n    <div class='pt-1'>\n      <AkLink\n        @color='primary'\n        @route='authenticated.dashboard.organization.namespaces'\n        @underline='always'\n        @title='{{t \"notificationModule.viewNamespaces\"}}'\n      >\n        <:default>\n          {{t 'notificationModule.viewNamespaces'}}\n        </:default>\n      </AkLink>\n    </div>\n  </div>\n</div>", {"contents":"<div>\n  <div data-test-nf-nsapprvd2-primary-message>\n    {{t\n      'notificationModule.messages.nf-nsapprvd2'\n      htmlSafe=true\n      platform_display=@context.platform_display\n      namespace_value=@context.namespace_value\n      namespace_created_on=@context.namespace_created_on\n      requester_username=@context.requester_username\n      moderator_username=@context.moderator_username\n    }}\n  </div>\n  <div>\n    <div class='pt-1'>\n      <AkLink\n        @color='primary'\n        @route='authenticated.dashboard.organization.namespaces'\n        @underline='always'\n        @title='{{t \"notificationModule.viewNamespaces\"}}'\n      >\n        <:default>\n          {{t 'notificationModule.viewNamespaces'}}\n        </:default>\n      </AkLink>\n    </div>\n  </div>\n</div>","moduleName":"irene/components/notifications-page/messages/nf-nsapprvd2/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-nsapprvd2/index.hbs"}});
import Component from '@glimmer/component';
import { type NfNsapprvd2Context } from './context';

export interface NotificationsPageMessagesNfNsapprvd2ComponentArgs {
  Args: {
    context: NfNsapprvd2Context;
  };
}

export default class NotificationsPageMessagesNfNsapprvd2Component extends Component<NotificationsPageMessagesNfNsapprvd2ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-nsapprvd2': typeof NotificationsPageMessagesNfNsapprvd2Component;
  }
}
