import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @spacing='1'>\n  <AkTypography data-test-nf-str-url-upldfailpayrq1-primary-message>\n    {{t\n      'notificationModule.messages.nf-str-url-upldfailpayrq1'\n      htmlSafe=true\n      store_name=(store-name-for-url @context.store_url)\n    }}\n  </AkTypography>\n\n  <div data-test-nf-str-url-upldfailpayrq1-link>\n    <AkButton\n      @tag='a'\n      @variant='text'\n      @color='primary'\n      @underline='always'\n      target='_blank'\n      href={{@context.store_url}}\n      rel='noopener noreferrer'\n    >\n      {{t 'notificationModule.viewAppOnStore'}}\n    </AkButton>\n  </div>\n</AkStack>", {"contents":"<AkStack @direction='column' @spacing='1'>\n  <AkTypography data-test-nf-str-url-upldfailpayrq1-primary-message>\n    {{t\n      'notificationModule.messages.nf-str-url-upldfailpayrq1'\n      htmlSafe=true\n      store_name=(store-name-for-url @context.store_url)\n    }}\n  </AkTypography>\n\n  <div data-test-nf-str-url-upldfailpayrq1-link>\n    <AkButton\n      @tag='a'\n      @variant='text'\n      @color='primary'\n      @underline='always'\n      target='_blank'\n      href={{@context.store_url}}\n      rel='noopener noreferrer'\n    >\n      {{t 'notificationModule.viewAppOnStore'}}\n    </AkButton>\n  </div>\n</AkStack>","moduleName":"irene/components/notifications-page/messages/nf-str-url-upldfailpayrq1/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-str-url-upldfailpayrq1/index.hbs"}});
import Component from '@glimmer/component';
import { type NfStrUrlUpldfailpayrq1Context } from './context';

export interface NotificationsPageMessagesNfStrUrlUpldfailpayrq1ComponentArgs {
  Args: {
    context: NfStrUrlUpldfailpayrq1Context;
  };
}

export default class NotificationsPageMessagesNfStrUrlUpldfailpayrq1Component extends Component<NotificationsPageMessagesNfStrUrlUpldfailpayrq1ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-str-url-upldfailpayrq1': typeof NotificationsPageMessagesNfStrUrlUpldfailpayrq1Component;
  }
}
