import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<NotificationsPage::NamespaceMessage\n  @context={{@context}}\n  @primaryMessage={{t\n    'notificationModule.messages.nf-nsreqstd1'\n    htmlSafe=true\n    requester_username=@context.requester_username\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n  }}\n/>", {"contents":"<NotificationsPage::NamespaceMessage\n  @context={{@context}}\n  @primaryMessage={{t\n    'notificationModule.messages.nf-nsreqstd1'\n    htmlSafe=true\n    requester_username=@context.requester_username\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n  }}\n/>","moduleName":"irene/components/notifications-page/messages/nf-nsreqstd1/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-nsreqstd1/index.hbs"}});
import Component from '@glimmer/component';
import { type NfNsreqstd1Context } from './context';

export interface NotificationsPageMessagesNfNsreqstd1ComponentArgs {
  Args: {
    context: NfNsreqstd1Context;
  };
}

export default class NotificationsPageMessagesNfNsreqstd1Component extends Component<NotificationsPageMessagesNfNsreqstd1ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-nsreqstd1': typeof NotificationsPageMessagesNfNsreqstd1Component;
  }
}
