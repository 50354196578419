import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-nf-upldfailnsunaprv1-primary-message>\n  {{t\n    'notificationModule.messages.nf-upldfailnsunaprv1'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n  }}\n</div>", {"contents":"<div data-test-nf-upldfailnsunaprv1-primary-message>\n  {{t\n    'notificationModule.messages.nf-upldfailnsunaprv1'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n  }}\n</div>","moduleName":"irene/components/notifications-page/messages/nf-upldfailnsunaprv1/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-upldfailnsunaprv1/index.hbs"}});
import Component from '@glimmer/component';
import { type NfUpldfailnsunaprv1Context } from './context';

export interface NotificationsPageMessagesNfUpldfailnsunaprv1ComponentArgs {
  Args: {
    context: NfUpldfailnsunaprv1Context;
  };
}

export default class NotificationsPageMessagesNfUpldfailnsunaprv1Component extends Component<NotificationsPageMessagesNfUpldfailnsunaprv1ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-upldfailnsunaprv1': typeof NotificationsPageMessagesNfUpldfailnsunaprv1Component;
  }
}
