import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span data-test-nf-nsapprvd1-primary-message>\n  {{t\n    'notificationModule.messages.nf-nsapprvd1'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    namespace_created_on=(format-date\n      @context.namespace_created_on day='numeric' month='long' year='numeric'\n    )\n    moderator_username=@context.moderator_username\n  }}\n</span>", {"contents":"<span data-test-nf-nsapprvd1-primary-message>\n  {{t\n    'notificationModule.messages.nf-nsapprvd1'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    namespace_created_on=(format-date\n      @context.namespace_created_on day='numeric' month='long' year='numeric'\n    )\n    moderator_username=@context.moderator_username\n  }}\n</span>","moduleName":"irene/components/notifications-page/messages/nf-nsapprvd1/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-nsapprvd1/index.hbs"}});
import Component from '@glimmer/component';
import { type NfNsapprvd1Context } from './context';

export interface NotificationsPageMessagesNfNsapprvd1ComponentArgs {
  Args: {
    context: NfNsapprvd1Context;
  };
}

export default class NotificationsPageMessagesNfNsapprvd1Component extends Component<NotificationsPageMessagesNfNsapprvd1ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-nsapprvd1': typeof NotificationsPageMessagesNfNsapprvd1Component;
  }
}
