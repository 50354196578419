import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @width='full'\n  @alignItems='center'\n  @justifyContent='center'\n  local-class='notification-dropdown-footer'\n  class='py-2'\n>\n  <AkLink\n    @color='primary'\n    @route={{this.viewAllNotificationsRoute}}\n    @underline='always'\n    @title='View notifications'\n    {{on 'click' this.onClick}}\n    data-test-notification-dropdown-link\n  >\n    <strong>View All Notifications</strong>\n  </AkLink>\n</AkStack>", {"contents":"<AkStack\n  @width='full'\n  @alignItems='center'\n  @justifyContent='center'\n  local-class='notification-dropdown-footer'\n  class='py-2'\n>\n  <AkLink\n    @color='primary'\n    @route={{this.viewAllNotificationsRoute}}\n    @underline='always'\n    @title='View notifications'\n    {{on 'click' this.onClick}}\n    data-test-notification-dropdown-link\n  >\n    <strong>View All Notifications</strong>\n  </AkLink>\n</AkStack>","moduleName":"irene/components/notifications-dropdown/footer/index.hbs","parseOptions":{"srcName":"irene/components/notifications-dropdown/footer/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface NotificationsDropdownComponentArgs {
  onViewAllNotificationClick: () => void;
  product: IreneProductVariants;
}

export default class NotificationsDropdownFooterComponent extends Component<NotificationsDropdownComponentArgs> {
  viewAllNotifsRoute = {
    appknox: 'authenticated.dashboard.notifications',
    storeknox: 'authenticated.storeknox.notifications',
  };

  get viewAllNotificationsRoute() {
    return this.viewAllNotifsRoute[this.args.product];
  }

  @action
  onClick() {
    this.args.onViewAllNotificationClick();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsDropdown::Footer': typeof NotificationsDropdownFooterComponent;
  }
}
