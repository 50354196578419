import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span data-test-nf-upldfailpay2-primary-message>\n  {{t\n    'notificationModule.messages.nf-upldfailpay2'\n    htmlSafe=true\n    package_name=@context.package_name\n    requester_username=@context.requester_username\n  }}\n</span>", {"contents":"<span data-test-nf-upldfailpay2-primary-message>\n  {{t\n    'notificationModule.messages.nf-upldfailpay2'\n    htmlSafe=true\n    package_name=@context.package_name\n    requester_username=@context.requester_username\n  }}\n</span>","moduleName":"irene/components/notifications-page/messages/nf-upldfailpay2/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-upldfailpay2/index.hbs"}});
import Component from '@glimmer/component';
import { type NfUpldfailpay2Context } from './context';

export interface NotificationsPageMessagesNfUpldfailpay2ComponentArgs {
  Args: {
    context: NfUpldfailpay2Context;
  };
}

export default class NotificationsPageMessagesNfUpldfailpay2Component extends Component<NotificationsPageMessagesNfUpldfailpay2ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-upldfailpay2': typeof NotificationsPageMessagesNfUpldfailpay2Component;
  }
}
