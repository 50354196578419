import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-nf-nsrejctd1-primary-message>\n  {{t\n    'notificationModule.messages.nf-nsrejctd1'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    namespace_created_on=(format-date\n      @context.namespace_created_on day='numeric' month='long' year='numeric'\n    )\n  }}\n</div>", {"contents":"<div data-test-nf-nsrejctd1-primary-message>\n  {{t\n    'notificationModule.messages.nf-nsrejctd1'\n    htmlSafe=true\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    namespace_created_on=(format-date\n      @context.namespace_created_on day='numeric' month='long' year='numeric'\n    )\n  }}\n</div>","moduleName":"irene/components/notifications-page/messages/nf-nsrejctd1/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-nsrejctd1/index.hbs"}});
import Component from '@glimmer/component';
import { type NfNsrejctd1Context } from './context';

export interface NotificationsPageMessagesNfNsrejctd1ComponentArgs {
  Args: {
    context: NfNsrejctd1Context;
  };
}

export default class NotificationsPageMessagesNfNsrejctd1Component extends Component<NotificationsPageMessagesNfNsrejctd1ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-nsrejctd1': typeof NotificationsPageMessagesNfNsrejctd1Component;
  }
}
