import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<NotificationsPage::NamespaceMessage\n  @context={{@context}}\n  @primaryMessage={{t\n    'notificationModule.messages.nf-nsreqstd2'\n    htmlSafe=true\n    current_requester_username=@context.current_requester_username\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    initial_requester_username=@context.initial_requester_username\n    namespace_created_on=(format-date\n      @context.namespace_created_on day='numeric' month='long' year='numeric'\n    )\n  }}\n/>", {"contents":"<NotificationsPage::NamespaceMessage\n  @context={{@context}}\n  @primaryMessage={{t\n    'notificationModule.messages.nf-nsreqstd2'\n    htmlSafe=true\n    current_requester_username=@context.current_requester_username\n    platform_display=@context.platform_display\n    namespace_value=@context.namespace_value\n    initial_requester_username=@context.initial_requester_username\n    namespace_created_on=(format-date\n      @context.namespace_created_on day='numeric' month='long' year='numeric'\n    )\n  }}\n/>","moduleName":"irene/components/notifications-page/messages/nf-nsreqstd2/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/nf-nsreqstd2/index.hbs"}});
import Component from '@glimmer/component';
import { type NfNsreqstd2Context } from './context';

export interface NotificationsPageMessagesNfNsreqstd2ComponentArgs {
  Args: {
    context: NfNsreqstd2Context;
  };
}

export default class NotificationsPageMessagesNfNsreqstd2Component extends Component<NotificationsPageMessagesNfNsreqstd2ComponentArgs> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'notifications-page/messages/nf-nsreqstd2': typeof NotificationsPageMessagesNfNsreqstd2Component;
  }
}
